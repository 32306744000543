import {
  AdditionalData,
  CallbackFunc,
  UserInteractionFilter,
} from "@geome/react-next/lib/context/userInteraction/types"
import { useSubscribeToUserInteraction } from "@geome/react-next/lib/hooks/userInteraction"
import { useCallback, useEffect, useState } from "react"

export const useSubscribeAndWaitForChange = <
  T extends AdditionalData,
  D extends { [key: string]: any }
>(
  filter: UserInteractionFilter,
  callback: CallbackFunc<AdditionalData, boolean>,
  dependencies: D
): void => {
  const [waitingData, setWaitingData] = useState<T | null>(null)

  const onPublish = useCallback(
    (_topic: string, data: T) => {
      setWaitingData(data)
    },
    [callback, setWaitingData]
  )

  useSubscribeToUserInteraction(filter, onPublish)

  useEffect(() => {
    if (waitingData) {
      const result = callback(filter, { ...waitingData, ...dependencies })
      if (result) setWaitingData(null)
    }
  }, [setWaitingData, waitingData, ...Object.values(dependencies)])
}
