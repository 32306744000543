import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import React, { MouseEvent, ReactElement } from "react"
import { QuakerstateLocation } from "../../types"
import { Link } from "@geome/react-components-next/lib/components/link"

export type TelephoneLinkProps = {
  location: QuakerstateLocation
  className: string
  onMouseEnter?: (e: MouseEvent) => void
  onMouseLeave?: (e: MouseEvent) => void
}

export const TelephoneLink = ({
  location,
  className,
  onMouseEnter,
  onMouseLeave,
}: TelephoneLinkProps): ReactElement => {
  const { translate } = useI18n()
  return (
    <Link
      name="telephone-link"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`telephone-link ${className}`}
      target="_blank"
      href={`tel:${location.telephone}`}
      interactionData={{ href: `tel:${location.telephone}`, location }}
    >
      {translate("info.call", { number: location.telephone })}
    </Link>
  )
}
