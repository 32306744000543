import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useBEM } from "@geome/react-next/lib/hooks/useBEM"
import React, { ReactElement } from "react"
import { QuakerstateLocation } from "../../types"

export const SiteType = ({ location }: { location: QuakerstateLocation }): ReactElement => {
  const { translate } = useI18n()
  const className = useBEM({
    block: "site-type",
    modifiers: {
      retail: location.user_type === "diy",
      "oil-change": location.user_type === "difm",
    },
  })

  return <div className={className}>{translate(`user_types.${location.user_type}`)}</div>
}
