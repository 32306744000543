import { useRecoilCallback, useRecoilValue } from "@geome/recoil"
import { selectionIntentAtom } from "@geome/recoil/dist/feature/selection/atoms"
import { SelectionIntent } from "@geome/types"
import { useEffect } from "react"
import { recentSearchesAtom } from "../../recoil/recentSearches"

export const CaptureRecentSearches = (): null => {
  const selectionIntent = useRecoilValue(selectionIntentAtom)

  const updateRecentSearches = useRecoilCallback(
    ({ set, snapshot }) =>
      async (si: SelectionIntent | null) => {
        const current = await snapshot.getPromise(recentSearchesAtom)

        if (si === null || isAlreadyInList(si, current)) return
        const next = [si, ...current.slice(0, 4)]
        set(recentSearchesAtom, next)
      },
    [selectionIntent]
  )

  useEffect(() => {
    updateRecentSearches(selectionIntent)
  }, [selectionIntent, updateRecentSearches])

  return null
}

const isAlreadyInList = (item: SelectionIntent, list: SelectionIntent[]): boolean =>
  list.some((savedItem) => {
    if (item.type === "google_place") return item["place_id"] === savedItem["place_id"]

    return item["id"] === savedItem["id"]
  })
