import React, { ReactElement } from "react"
import useResizeObserver from "use-resize-observer"

import { useMatchMedia } from "@geome/react-next/lib/hooks/useMatchMedia"
import { useSetUIPadding } from "@geome/recoil/dist/feature/map/hooks"
import { Search } from "../search"
import {
  useIsShowingMapClusters,
  useLocations,
  useSelectedLocation,
} from "@geome/recoil/dist/feature/locations/hooks"
import { QuakerstateLocation, QuakerstateModal } from "../../types"
import { useMobileMapListState } from "@geome/recoil/dist/feature/ui/hooks"
import { useModalState } from "@geome/recoil/dist/feature/ui/hooks"
import { Filters } from "../filters"
import { useBEM } from "@geome/react-next/lib/hooks/useBEM"
import { TabButton } from "@geome/react-components-next/lib/components/buttons/tab"
import { MapIcon } from "../icons/map"
import { ListIcon } from "../icons/list"
import { LocationsList } from "../locationsList"
import { InfoWindow } from "../infoWindow"
import { FiltersButton } from "../filters/button"

const AppSideBar = (): ReactElement => {
  const setUIPadding = useSetUIPadding()
  const isMobile = useMatchMedia("(max-width: 767px)")

  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: (size) => {
      if (isMobile) {
        setUIPadding({ padding: { top: 0, left: 0, right: 0, bottom: 0 }, resizeMap: false })
        return
      }
      const padding = { top: 0, left: size.width || 0, right: 0, bottom: 0 }
      const resizeMap = (size.height || 0) > window.innerHeight * 0.8
      setUIPadding({ padding, resizeMap })
    },
  })

  const selectedLocation = useSelectedLocation<QuakerstateLocation>()
  const { mobileView, setMobileView } = useMobileMapListState()
  const { currentModal } = useModalState<QuakerstateModal>()
  const isShowingClusters = useIsShowingMapClusters({ resource: "locations" })

  // Can replace these all with :has(.app__sidebar-content:not(:empty) once that's a thing
  const listLocations = useLocations({ resource: "locations", endpoint: "nearest_to" })
  const showList =
    (!isMobile || mobileView === "list") && !isShowingClusters && listLocations.length > 0
  const showInfoWindow = selectedLocation !== null
  const showFilters = currentModal?.name === "Filters"

  const className = useBEM({
    block: "app",
    element: "sidebar",
    modifiers: { mobile: isMobile, "has-content": showFilters || showInfoWindow || showList },
  })

  return (
    <div className={className} ref={ref}>
      <Search />
      <nav className="app__sidebar-nav">
        {isMobile && !isShowingClusters && (
          <>
            <TabButton
              name="map"
              renderIcon={() => <MapIcon />}
              active={mobileView === "map"}
              configPath="mobile_nav."
              onClick={() => setMobileView("map")}
            />
            <TabButton
              name="list"
              renderIcon={() => <ListIcon />}
              active={mobileView === "list"}
              configPath="mobile_nav."
              onClick={() => setMobileView("list")}
            />
          </>
        )}

        <FiltersButton />
      </nav>
      <div className="app__sidebar-content">
        {showList && <LocationsList />}
        {showInfoWindow && <InfoWindow />}
        {showFilters && <Filters />}
      </div>
    </div>
  )
}
export { AppSideBar }
