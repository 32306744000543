import { useMemo } from "react"
import { Lat, Lng } from "@geome/types"

export const useGoogleDirectionsLink = ({ lat, lng }: { lat: Lat; lng: Lng }): string =>
  useMemo(() => {
    const url = new URL("https://www.google.com/maps/dir/?api=1")
    url.searchParams.set("destination", `${lat},${lng}`)

    return url.toString()
  }, [lat, lng])
