import { HTMLOverlay } from "@geome/react-components-next/lib/components/map/google/htmlOverlay"
import { useBEM } from "@geome/react-next/lib/hooks/useBEM"
import {
  useHighlightedLocation,
  useIsShowingMapClusters,
} from "@geome/recoil/dist/feature/locations/hooks"
import React, { ReactElement } from "react"
import { QuakerstateLocation } from "../../types"
import { Address } from "../address"
import { SiteType } from "../siteType"

export const LocationTooltip = (): ReactElement | null => {
  const highlightedLocation = useHighlightedLocation<QuakerstateLocation>()
  const showingClusters = useIsShowingMapClusters({ resource: "locations" })

  const className = useBEM({
    block: "location-tooltip",
    modifiers: {
      "is-selected": Boolean(highlightedLocation?.isSelected),
    },
  })

  if (!highlightedLocation || showingClusters) return null

  return (
    <HTMLOverlay lat={highlightedLocation.lat} lng={highlightedLocation.lng}>
      <div className={className}>
        <div className="location-tooltip__name">{highlightedLocation.name}</div>
        <div className="location-tooltip__address">
          <Address location={highlightedLocation} />
        </div>
        <div className="location-tooltip__site-type">
          <SiteType location={highlightedLocation} />
        </div>
      </div>
    </HTMLOverlay>
  )
}
