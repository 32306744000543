import defaultMessages from "./default"
const requireContext = require.context("./", true, /([a-z][a-z]_[A-Z][A-Z])/)

export default {
  ...defaultMessages,
  localised: {
    ...Object.fromEntries(
      requireContext
        .keys()
        .map((key) => [key.replace("./", "").replace(".ts", ""), requireContext(key).default])
    ),
  },
}
