import React, { ReactElement } from "react"

export const CloseIcon = (): ReactElement => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="20.9302"
      y="6.78833"
      width="0.8"
      height="20"
      transform="rotate(45 20.9302 6.78833)"
      fill="#222222"
    />
    <rect
      x="7.354"
      y="6.78833"
      width="20"
      height="0.8"
      transform="rotate(45 7.354 6.78833)"
      fill="#222222"
    />
  </svg>
)
