export default {
  search: {
    placeholder: "Escriba su ubicación",
    your_location: "Utilizar mi ubicación",
    use_my_location: "Utilizar mi ubicación",
    geolocation_status: {
      none: "Utilizar mi ubicación",
    },
  },
  info_window: {
    directions_link_no_distance: "<u>Buscar</u>",
    directions_link: "<u>Buscar</u> ({distance, number, distance} miles)",
  },
  directions: { distance: "{distance, number, distance} Miles" },
  filters: {
    title: "Filtros",
    button: {
      aria_text: "Filtros",
      aria_count: "Active Filters: ",
    },
  },
  user_types: {
    difm: "Establecimientos de cambio de aceite",
    diy: "Establecimientos minoristas",
  },
  mobile_nav: {
    tabs: {
      map: "Mapa",
    },
  },
}
