import React, { ReactElement } from "react"

export const BottleIcon = ({ scale = 1 }: { scale?: number }): ReactElement => (
  <svg
    width={9 * scale}
    height={20 * scale}
    viewBox="0 0 9 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0C5.44772 0 5 0.447715 5 1L5 3L1.00008 5H1C0.447715 5 0 5.44772 0 6V19C0 19.5523 0.447715 20 1 20H8C8.55229 20 9 19.5523 9 19V6C9 5.81216 8.94821 5.63642 8.85814 5.48628L8 3.47738V1C8 0.447715 7.55228 0 7 0H6Z"
      fill="currentColor"
    />
  </svg>
)
