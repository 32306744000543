import React, { ReactElement } from "react"

export const FiltersIcon = (): ReactElement => (
  <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.26491 3H6.26491V0H8.26491V3ZM10.3996 8H12.3996V5H10.3996V8ZM2.79738 13H4.79738V10H2.79738V13ZM13 7.5H15V5.5H13V7.5ZM9.79917 7.5H0V5.5H9.79917V7.5ZM0 12.5H2.19449V10.5H0V12.5ZM15.0001 12.5H5.40039V10.5H15.0001V12.5ZM0 0.5V2.5H5.66671V0.5H0ZM8.86311 2.5V0.5H15V2.5H8.86311Z"
      fill="#404040"
    />
  </svg>
)
