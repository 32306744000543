import {
  PaintFunc,
  useCanvasIcon,
} from "@geome/react-components-next/lib/hooks/marker/useCanvasIcon"
import { useCSSCustomProperty } from "@geome/react-next/lib/hooks/useCSSCustomProperty"
import { QuakerstateLocation } from "../../../types"

const MAX_TEXT_WIDTH = 20

export const useQuakerstateIcon = (subject: QuakerstateLocation): google.maps.Icon => {
  const primaryColour = useCSSCustomProperty("--primary-colour", "red")

  const paintFunc: PaintFunc<QuakerstateLocation> = ({ context, subject }) => {
    let scalingFactor = 1

    if (subject.isHighlighted) scalingFactor = 1.3
    if (subject.isSelected) scalingFactor = 1.6

    const isRetail = subject.user_type === "diy"

    const WIDTH = Math.round(31 * scalingFactor)
    const HEIGHT = Math.round(40 * scalingFactor)
    context.canvas.width = WIDTH * 2
    context.canvas.height = HEIGHT * 2
    context.scale(2 * scalingFactor, 2 * scalingFactor)

    const markerPath = new Path2D(
      "M14 0C10.287 0 6.72601 1.475 4.1005 4.1005C1.475 6.72601 0 10.287 0 14C0 24.5 14 40 14 40C14 40 28 24.5 28 14C28 10.287 26.525 6.72601 23.8995 4.1005C21.274 1.475 17.713 0 14 0Z"
    )
    context.save()
    // shadow marker
    context.translate(3, 0)
    context.fillStyle = "rgba(0,0,0,0.1)"
    context.fill(markerPath)
    context.restore()

    context.save()
    context.fillStyle = isRetail ? "#222" : primaryColour
    context.fill(markerPath)
    context.restore()

    if (subject.index) {
      context.fillStyle = "white"
      context.textAlign = "center"
      context.textBaseline = "middle"

      let fontSize = 18

      do {
        fontSize -= 0.5
        context.font = `${fontSize}px Roboto,sans-serif`
      } while (context.measureText(`${subject.index}`).width > MAX_TEXT_WIDTH)

      context.fillText(
        `${subject.index}`,
        (WIDTH - 3) / (2 * scalingFactor),
        HEIGHT / (2.5 * scalingFactor)
      )
    }

    return {
      scaledSize: { width: WIDTH, height: HEIGHT },
      anchor: {
        x: WIDTH / 2 - 2,
        y: HEIGHT,
      },
    }
  }

  const icon = useCanvasIcon<QuakerstateLocation>({
    subject,
    paintFunc,
    cacheKeyFunc: ({ subject }) =>
      `${subject.index}-${subject.isHighlighted}-${subject.isSelected}-${Boolean(
        subject.user_type === "diy"
      )}`,
  })
  return icon
}
