const store = window.localStorage

export const persistLocally =
  <T>(key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = store.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue: T) => {
      store.setItem(key, JSON.stringify(newValue))
    })
  }
