import React from "react"
import { Search as GenericSearch } from "@geome/react-components-next/lib/components/search/search"
import { MagnifyingGlassIcon } from "../icons/magnifyingGlass"
import { CaptureRecentSearches } from "./captureRecentSearches"
import { RecentSearches } from "./recentSearches"
import { BasicPinIcon as PinIcon } from "../icons/basicPin"
import { CSSCustomPropertyContextProvider } from "@geome/react-next/lib/context/cssCustomProperty"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"

export const Search = (): JSX.Element => (
  <BlockScopingContextProvider block="search">
    <CSSCustomPropertyContextProvider properties={{ "--error-colour": "#D51E20" }}>
      <div className="search__wrapper">
        <GenericSearch
          completionProps={{
            hasGeolocation: true,
            renderPreSearchContent: (onSelect) => <RecentSearches onSelect={onSelect} />,
          }}
          renderCompletions={{
            completions: {
              google: ({ description }) => (
                <>
                  <PinIcon />
                  <span>{description}</span>
                </>
              ),
              locations: ({ value }) => (
                <>
                  <PinIcon />
                  <span>{value}</span>
                </>
              ),
            },
          }}
          renderSearchIcon={() => <MagnifyingGlassIcon />}
        />
      </div>
      <CaptureRecentSearches />
    </CSSCustomPropertyContextProvider>
  </BlockScopingContextProvider>
)
