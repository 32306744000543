import { AppConfig, DeployEnv } from "@geome/types"
import { FiltersConfig } from "./filters"
import intlDataMessages from "./intlData/locales"

// I wonder where this can go?
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    interface ProcessEnv {
      GOOGLE_API_KEY: string
      DEPLOY_ENV: DeployEnv
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
const configEnv: DeployEnv = ["prod", "uat", "test"].includes(process.env.DEPLOY_ENV || "")
  ? process.env.DEPLOY_ENV
  : "test"

const config: AppConfig = {
  locale: "en_US",
  configEnv: configEnv,
  baseUrl: "/api/v2/qs",
  intlData: {
    formats: {
      number: {
        distance: {
          maximumFractionDigits: 1,
          style: "decimal",
          unit: "mile",
          localised: {
            en_CA: {
              unit: "kilometer",
            },
            fr_CA: {
              unit: "kilometer",
            },
          },
        },
      },
    },
    messages: intlDataMessages,
  },
  completions: {},
  resources: {
    locations: {
      enabled: true,
      views: ["within_bounds", "nearest_to", "complete"],
    },
  },
  gApiSettings: {
    key: process.env.GOOGLE_API_KEY,
  },
  map: {
    defaultTargetZoom: "14..18",
    settings: {
      zoom: 6,
      center: {
        lat: 55,
        lng: -4,
      },
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
      ],
    },
  },
  filters: FiltersConfig,
}

export { config }
