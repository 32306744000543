import React from "react"
import "@fontsource/roboto"
import "@fontsource/roboto-condensed"
import { ConfigContextProvider } from "@geome/react-next/lib/context/config"
import { GoogleMapsLoaderUsingConfig } from "@geome/react-components-next/lib/components/map/google/loader"
import { config } from "../../config"
import { Resources } from "../resources"
import { I18nContextProviderFromConfig } from "@geome/react-next/lib/context/i18nContext/provider"
import { AppSideBar } from "./sidebar"
import { initialiseFilterState } from "@geome/recoil/dist/feature/filters/initialisers"
import { initialiseResourcesState } from "@geome/recoil/dist/feature/resources/initialisers"
import { AppConfig } from "@geome/types"
import { RecoilMultiInitRoot } from "@geome/recoil"
import { GeospatialApiRequesterContextProvider } from "@geome/geospatial-api-requester"
import { CSSCustomPropertyContextProvider } from "@geome/react-next/lib/context/cssCustomProperty"
import DistanceFormatHelper from "@geome/intl/lib/formatHelper/distance"
import { Map } from "../map"
import { Analytics } from "../analytics"
import { UserInteractionContextProvider } from "@geome/react-next/lib/context/userInteraction"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"

const App = (): JSX.Element => (
  <ConfigContextProvider value={config}>
    {(finalConfig: AppConfig) => (
      <RecoilMultiInitRoot
        initialisers={[initialiseFilterState(finalConfig), initialiseResourcesState(finalConfig)]}
      >
        <UserInteractionContextProvider>
          <GeospatialApiRequesterContextProvider>
            <I18nContextProviderFromConfig formatHelpers={[DistanceFormatHelper]}>
              <GoogleMapsLoaderUsingConfig>
                <CSSCustomPropertyContextProvider
                  properties={{
                    "--primary-colour": "#00843D",
                    "--search-input__text-colour": "#404040",
                  }}
                >
                  <BlockScopingContextProvider block="app">
                    <div className="app">
                      <AppSideBar />
                      <Map />
                      <Resources />
                      <Analytics />
                    </div>
                  </BlockScopingContextProvider>
                </CSSCustomPropertyContextProvider>
              </GoogleMapsLoaderUsingConfig>
            </I18nContextProviderFromConfig>
          </GeospatialApiRequesterContextProvider>
        </UserInteractionContextProvider>
      </RecoilMultiInitRoot>
    )}
  </ConfigContextProvider>
)

export { App }
