import React, { ReactElement } from "react"

export const ListIcon = (): ReactElement => (
  <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2H2V0H0V2ZM2 6H0V4H2V6ZM2 10H0V8H2V10ZM18 6H4V4H18V6ZM4 10H18V8H4V10ZM4 2V0H18V2H4Z"
      fill="currentColor"
    />
  </svg>
)
