import React, { ReactElement } from "react"
import { useSetRecoilState } from "@geome/recoil"
import { Locale } from "@geome/types"
import { useConfig } from "@geome/react-next/lib/hooks/config"
import { targetBoundsAtom } from "@geome/recoil/dist/feature/map/atoms"
import { useClusters } from "@geome/recoil/dist/feature/locations/hooks"
import { HTMLOverlay } from "@geome/react-components-next/lib/components/map/google/htmlOverlay"

const formatSize = (size: number, locale?: Locale): string =>
  locale ? size.toLocaleString(locale.replace("_", "-")) : `${size}`

export const Clusters = (): ReactElement => {
  const locale = useConfig<Locale>("locale", false)
  const setTarget = useSetRecoilState(targetBoundsAtom)
  const clusters = useClusters({ resource: "locations", endpoint: "within_bounds" })
  return (
    <>
      {clusters.map(
        ({
          id,
          size,
          bounds: {
            ne: [north, east],
            sw: [south, west],
          },
          centroid: [lat, lng],
        }) => (
          <HTMLOverlay key={id} lat={lat} lng={lng}>
            <div
              className="cluster"
              onClick={() =>
                setTarget({
                  north,
                  east,
                  south,
                  west,
                })
              }
            >
              {formatSize(size, locale)}
            </div>
          </HTMLOverlay>
        )
      )}
    </>
  )
}
