import { useConfig } from "@geome/react-next/lib/hooks/config"
import { AppConfig } from "@geome/types"
import React, { ReactElement, useMemo } from "react"
import { QuakerstateLocation } from "../../types"

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Intl {
  class ListFormat {
    constructor(locales?: string | string[], options?: any)
    public format: (items: string[]) => string
  }
}

export const Address = ({ location }: { location: QuakerstateLocation }): ReactElement => {
  const locale = useConfig<AppConfig["locale"]>("locale")

  const listFormatter = useMemo(() => {
    const lang = locale ? locale.split("_")[0] : "en"

    //@ts-check ignore
    return new Intl.ListFormat(lang, { style: "long", type: "unit" })
  }, [])

  return (
    <div>
      <div>{listFormatter.format([location.address1, ""])}</div>
      {listFormatter.format([location.city, location.state, location.postcode].filter(Boolean))}
    </div>
  )
}
