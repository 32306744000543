import { CallbackFunc } from "@geome/react-next/lib/context/userInteraction/types"
import { APIFilter, APIFilters, Filter, GooglePlaceLiteral, SelectionIntent } from "@geome/types"
import { QuakerstateLocation } from "../../types"
import { sendAnalytics } from "./sendAnalytics"

export type CompletionSelectArgs = {
  completion: SelectionIntent
  selectedPlace: GooglePlaceLiteral | null
  selectedLocation: QuakerstateLocation | null
  filters: APIFilters
}

export const sendLocationSelect = (location: QuakerstateLocation, filters: APIFilters): void => {
  const { name, city, country, user_type, id } = location

  sendAnalytics({
    action: "stationclick",
    text: JSON.stringify({ id, name }),
    searchLocation: user_type === "difm" ? "oil change location" : "retail location",
    eventCategory: `Store Locator ${stringifyUserTypesFilter(filters)} stationclick`,
    eventAction: `marker selected`,
    eventLabel: `${name}, ${city}, ${country}`,
  })
}

export const sendGooglePlaceSelect = (place: GooglePlaceLiteral, filters: APIFilters): void => {
  sendAnalytics({
    action: "search",
    eventCategory: `Store Locator ${stringifyUserTypesFilter(filters)} search`,
    eventAction: `search selected`,
    eventLabel: `${place.city}, ${place.country}`,
    text: { country: place.country, city: place.city },
  })
}

export const onCompletionSelect: CallbackFunc<CompletionSelectArgs, boolean> = (_topic, data) => {
  const { completion: selectionIntent, selectedPlace, filters, selectedLocation } = data
  if (
    selectionIntent.type === "location" &&
    selectedLocation &&
    selectedLocation.id === selectionIntent.id
  ) {
    sendLocationSelect(selectedLocation, filters)
    return true
  }
  if (
    selectionIntent.type === "google_place" &&
    selectedPlace &&
    selectionIntent.place_id === selectedPlace.place_id
  ) {
    sendGooglePlaceSelect(selectedPlace, filters)
    return true
  }
  return false
}

export const onFilterToggle: CallbackFunc<Filter> = (_topic, filter) => {
  if (filter.active && filter.groupName === "user_types") {
    sendAnalytics({
      action: "togglelocations",
      text: JSON.stringify({ name: filterNameToReadable(filter) }),
    })
  }
}

// helpers
export const stringifyUserTypesFilter = (filters: APIFilters | null): string => {
  if (!filters) return ""
  return filters
    .filter((f) => f.groupName === "user_types" && f["active"])
    .map(filterNameToReadable)
    .join("")
}

const filterNameToReadable = (f: Filter | APIFilter): string => {
  switch (f.filterName) {
    case "difm":
      return "oil change"
    case "diy":
      return "retail"
    default:
      return "N/A"
  }
}
