export default {
  info: {
    call: "Call {number}",
    to_make_appointment: "To make an appointment:",
  },
  search: {
    placeholder: "Search location or facilities",
    your_location: "Your location",
    use_my_location: "Use my location",
    geolocation_status: {
      none: "Use my location",
      error: "Unable to find your location",
      success: "Using your location",
      waiting: "Finding your location",
    },
    aria: {
      back_button: "Cancel search",
      clear: "Clear search",
    },
    completions: {
      recent: "Recent",
      clear_all_recent: "Clear all recent",
    },
  },
  info_window: {
    close: "Close site details",
    directions_link_no_distance: "<u>Get Directions</u>",
    directions_link: "<u>Get Directions</u> ({distance, number, distance} miles)",
  },
  directions: { distance: "{distance, number, distance} Miles" },
  locations_list: {
    item: {
      telephone: "<b>Tel:</b> {number}",
      distance: "{distance, number, ::.0/w unit/kilometer}",
    },
    aria: {
      loading: "Loading locations",
    },
  },
  filters: {
    title: "Filters",
    close: "Close filters",
    group_heading: "{group}",
    user_types: { title: "Location Type" },
    button: {
      aria_text: "Filters",
      aria_count: "Active Filters: ",
    },
  },
  user_types: {
    difm: "Oil Change",
    diy: "Retail",
  },
  mobile_nav: {
    tabs: {
      map: "MAP",
      list: "LIST",
    },
  },
}
