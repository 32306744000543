import { useLocations } from "@geome/recoil/dist/feature/locations/hooks"
import React, { ReactElement } from "react"
import { QuakerstateMarker } from "./quakerStateMarker"
import { QuakerstateLocation } from "../../types"

export const Locations = (): ReactElement => {
  const locations = useLocations<QuakerstateLocation>({
    resource: "locations",
    endpoint: "within_bounds",
  })
  return (
    <>
      {locations.map((location) => (
        <QuakerstateMarker key={location.id} location={location} />
      ))}
    </>
  )
}
