import React, { ReactElement } from "react"

export const TruckIcon = (): ReactElement => (
  <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1067 0H1.37335C0.90207 0 0.52002 0.447715 0.52002 1V9C0.52002 9.55229 0.90207 10 1.37335 10H1.48002C1.48002 11.1046 2.33963 12 3.40002 12C4.46041 12 5.32002 11.1046 5.32002 10H6.28002C6.28002 11.1046 7.13963 12 8.20002 12C9.26041 12 10.12 11.1046 10.12 10H13H13.1067H14.92C14.92 11.1046 15.7796 12 16.84 12C17.9004 12 18.76 11.1046 18.76 10C19.2902 10 19.72 9.55229 19.72 9V6H19.7201V5H19.72C19.72 3.34315 18.4306 2 16.84 2H15.88C15.3498 2 14.92 2.44772 14.92 3V9H13.96V1C13.96 0.447715 13.578 0 13.1067 0ZM16.84 8C17.5507 8 18.1712 8.4022 18.5032 9H18.7601V6H15.8801V8.2675C16.1625 8.09737 16.4903 8 16.84 8ZM8.20002 8C8.91069 8 9.53118 8.4022 9.86316 9H13V1H1.48002V9H1.73688C2.06886 8.4022 2.68935 8 3.40002 8C4.11069 8 4.73118 8.4022 5.06316 9H6.53688C6.86886 8.4022 7.48935 8 8.20002 8ZM18.7601 5C18.7601 3.89543 17.9005 3 16.8401 3H15.8801V5H18.7601ZM4.36002 10C4.36002 10.5523 3.93021 11 3.40002 11C2.86983 11 2.44002 10.5523 2.44002 10C2.44002 9.44771 2.86983 9 3.40002 9C3.93021 9 4.36002 9.44771 4.36002 10ZM9.16002 10C9.16002 10.5523 8.73021 11 8.20002 11C7.66983 11 7.24002 10.5523 7.24002 10C7.24002 9.44771 7.66983 9 8.20002 9C8.73021 9 9.16002 9.44771 9.16002 10ZM17.8001 10C17.8001 10.5523 17.3703 11 16.8401 11C16.3099 11 15.8801 10.5523 15.8801 10C15.8801 9.44771 16.3099 9 16.8401 9C17.3703 9 17.8001 9.44771 17.8001 10Z"
      fill="#404040"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1067 0H1.37335C0.90207 0 0.52002 0.447715 0.52002 1V9C0.52002 9.55229 0.90207 10 1.37335 10H1.48002C1.48002 11.1046 2.33963 12 3.40002 12C4.46041 12 5.32002 11.1046 5.32002 10H6.28002C6.28002 11.1046 7.13963 12 8.20002 12C9.26041 12 10.12 11.1046 10.12 10H13H13.1067H14.92C14.92 11.1046 15.7796 12 16.84 12C17.9004 12 18.76 11.1046 18.76 10C19.2902 10 19.72 9.55229 19.72 9V6H19.7201V5H19.72C19.72 3.34315 18.4306 2 16.84 2H15.88C15.3498 2 14.92 2.44772 14.92 3V9H13.96V1C13.96 0.447715 13.578 0 13.1067 0ZM16.84 8C17.5507 8 18.1712 8.4022 18.5032 9H18.7601V6H15.8801V8.2675C16.1625 8.09737 16.4903 8 16.84 8ZM8.20002 8C8.91069 8 9.53118 8.4022 9.86316 9H13V1H1.48002V9H1.73688C2.06886 8.4022 2.68935 8 3.40002 8C4.11069 8 4.73118 8.4022 5.06316 9H6.53688C6.86886 8.4022 7.48935 8 8.20002 8ZM18.7601 5C18.7601 3.89543 17.9005 3 16.8401 3H15.8801V5H18.7601ZM4.36002 10C4.36002 10.5523 3.93021 11 3.40002 11C2.86983 11 2.44002 10.5523 2.44002 10C2.44002 9.44771 2.86983 9 3.40002 9C3.93021 9 4.36002 9.44771 4.36002 10ZM9.16002 10C9.16002 10.5523 8.73021 11 8.20002 11C7.66983 11 7.24002 10.5523 7.24002 10C7.24002 9.44771 7.66983 9 8.20002 9C8.73021 9 9.16002 9.44771 9.16002 10ZM17.8001 10C17.8001 10.5523 17.3703 11 16.8401 11C16.3099 11 15.8801 10.5523 15.8801 10C15.8801 9.44771 16.3099 9 16.8401 9C17.3703 9 17.8001 9.44771 17.8001 10Z"
      fill="white"
    />
  </svg>
)
