import React from "react"
import { createRoot } from "react-dom/client"
import { App } from "./components/app"

import "../iframe-test.html"

const render = (): void => {
  const container = document.getElementById("app")
  if (!container) throw Error("`.app` element missing")
  const root = createRoot(container)
  root.render(<App />)
}

document.addEventListener("DOMContentLoaded", () => render())
